import {
  LANG_KEYS,
  ROUTER_PATHS,
  SUPPORTED_FILE_FORMATS,
} from "@/shared/constants";
import { ChangeEvent, ComponentType, FC, ReactNode, useRef } from "react";
import { getBase64FromFile } from "@/shared/utils";
import { useAutoTranslation, useRecordProcessStore } from "@/entities";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

interface Props {
  children: ReactNode;
  btnClassname?: string;
  wrapper?:
    | ComponentType<{ children: ReactNode }>
    | keyof JSX.IntrinsicElements;
}

export const FileTranscribe: FC<Props> = ({
  children,
  btnClassname,
  wrapper: Wrapper = "div",
}) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const { startTranscribe, transcribing } = useRecordProcessStore();
  const navigate = useNavigate();
  const { t } = useAutoTranslation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    if (!file) return;
    const nameArr = file.name.split(".");
    const extension = nameArr.pop()!;
    if (!SUPPORTED_FILE_FORMATS.includes(extension)) {
      toast.error(
        t(
          LANG_KEYS.UNSUPPORTED_FILE_FORMAT.key,
          LANG_KEYS.UNSUPPORTED_FILE_FORMAT.value,
        ),
      );
      return;
    }
    navigate(ROUTER_PATHS.HOME);
    const base64 = await getBase64FromFile(file);

    startTranscribe({ base64, extension });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const handleButtonClick = () => {
    if (fileInputRef.current && !transcribing) {
      fileInputRef.current.click();
    }
  };

  return (
    <Wrapper>
      <button
        type="button"
        className={btnClassname}
        onClick={handleButtonClick}
      >
        {children}
      </button>
      <input
        ref={fileInputRef}
        type="file"
        className="hidden"
        onChange={handleFileChange}
        accept={SUPPORTED_FILE_FORMATS.map((ext) => `.${ext}`).join(",")}
      />
    </Wrapper>
  );
};
