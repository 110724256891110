import { List, ListItem } from "./ui.tsx";
import { BASIC_INFO, LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";
import { useTemplateStrings } from "@/shared/utils";

export const PricingOptionsListsPro = () => {
  const { t } = useAutoTranslation();
  const templateStrings = useTemplateStrings();
  return (
    <List>
      <ListItem>
        {t(
          LANG_KEYS.WEB_AND_MOBILE_RECORDING.key,
          LANG_KEYS.WEB_AND_MOBILE_RECORDING.value,
        )}
      </ListItem>
      <ListItem>{templateStrings.upToXMinuterPerNote("60")}</ListItem>
      <ListItem>
        {t(
          LANG_KEYS.MULTI_VOICE_TRANSCRIPTION.key,
          LANG_KEYS.MULTI_VOICE_TRANSCRIPTION.value,
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.UNLIMITED_VOICE_NOTES.key,
          LANG_KEYS.UNLIMITED_VOICE_NOTES.value,
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.RECORD_IN_ANY_LANGUAGE.key,
          LANG_KEYS.RECORD_IN_ANY_LANGUAGE.value,
        )}
      </ListItem>
      <ListItem>
        {templateStrings.uploadAudioOrVideoFilesUpToXMb("300")}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.NOTES_AND_SUMMARIES_ARE_SAVED_FOREVER.key,
          LANG_KEYS.NOTES_AND_SUMMARIES_ARE_SAVED_FOREVER.value,
        )}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.ADD_YOUR_CUSTOM_PROMPT.key,
          LANG_KEYS.ADD_YOUR_CUSTOM_PROMPT.value,
        )}
      </ListItem>
      <ListItem>
        <a href={BASIC_INFO.GOOGLE_PLAY_APP_LINK} target="_blank">
          Android
        </a>
        &nbsp; {t(LANG_KEYS.AND.key, LANG_KEYS.AND.value).toLowerCase()}&nbsp;
        <a href={BASIC_INFO.IOS_APP_LINK} target="_blank">
          iPhone
        </a>
        &nbsp;{t(LANG_KEYS.APPS.key, LANG_KEYS.APPS.value)}
      </ListItem>
      <ListItem>
        {t(
          LANG_KEYS.AUTOMATIC_EXPORT_TO_NOTION.key,
          LANG_KEYS.AUTOMATIC_EXPORT_TO_NOTION.value,
        )}
      </ListItem>
      <ListItem>
        <a href={BASIC_INFO.WHATS_APP_BOT_LINK} target="_blank">
          WhatsApp {t(LANG_KEYS.BOT.key, LANG_KEYS.BOT.value)}
        </a>
        &nbsp;
        {t(
          LANG_KEYS.WITH_ALL_AUDIONOTES_FEATURES.key,
          LANG_KEYS.WITH_ALL_AUDIONOTES_FEATURES.value,
        )}
      </ListItem>
      <ListItem>
        <a href={BASIC_INFO.TELEGRAM_BOT_LINK} target="_blank">
          Telegram {t(LANG_KEYS.BOT.key, LANG_KEYS.BOT.value)}
        </a>
        &nbsp;
        {t(
          LANG_KEYS.WITH_ALL_AUDIONOTES_FEATURES.key,
          LANG_KEYS.WITH_ALL_AUDIONOTES_FEATURES.value,
        )}
      </ListItem>
      <ListItem>
        Zapier&nbsp;
        <a href={BASIC_INFO.ZAPIER_INTEGRATION_LINK} target="_blank">
          {t(LANG_KEYS.INTEGRATION.key, LANG_KEYS.INTEGRATION.value)}
        </a>
      </ListItem>
    </List>
  );
};
