import {
  EditNoteDtoReq,
  Note,
  notesControllerEditNote,
} from "@/shared/api/generated.ts";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useEditNoteStore } from "@/entities";
import { QUERY_KEYS } from "@/shared/constants";

type FormData = EditNoteDtoReq;
export function useEditNoteForm(note: Note) {
  const { closeEdit } = useEditNoteStore();
  const queryClient = useQueryClient();
  const { register, handleSubmit, getValues, setValue, watch } =
    useForm<FormData>({
      defaultValues: {
        id: note.id,
        list: note.list,
        original: note.original,
        paragraph: note.paragraph,
        title: note.title,
      },
    });

  const formMutation = useMutation({
    mutationFn: notesControllerEditNote,
    async onSuccess() {
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
      closeEdit();
    },
  });

  return {
    register,
    isLoading: formMutation.isPending,
    getValues,
    setValue,
    watch,
    handleSubmit: handleSubmit((values) => {
      formMutation.mutate(values);
    }),
  };
}
