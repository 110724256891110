import { create } from "zustand";
import { devtools } from "zustand/middleware";

type StartTranscribePayload = {
  base64: string;
  extension: string;
};

type Store = {
  base64: string | null;
  extension: string | null;
  transcribing: boolean;
  startTranscribe: (data: StartTranscribePayload) => void;
  endTranscribe: () => void;
  fastRecord: boolean;
  startFastRecord: () => void;
  finishFastRecord: () => void;
};

export const useRecordProcessStore = create<Store>()(
  devtools(
    (set, getState) => ({
      base64: null,
      extension: null,
      transcribing: false,
      startTranscribe: (data: StartTranscribePayload) => {
        set({
          base64: data.base64,
          extension: data.extension,
          transcribing: true,
        });
      },
      endTranscribe: () => {
        set({ base64: null, extension: null, transcribing: false });
      },

      fastRecord: false,
      startFastRecord: () => {
        const currentState = getState();
        if (!currentState.transcribing) {
          set({ fastRecord: true });
        }
      },
      finishFastRecord: () => {
        set({ fastRecord: false });
      },
    }),
    { name: "RecordProcessStore" },
  ),
);
