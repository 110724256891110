import { EditableForm } from "./form";
import styled from "styled-components";
import { Note } from "@/shared/api/generated.ts";
import { FC } from "react";

interface Props {
  data: Note;
}

export const EditNoteForm: FC<Props> = ({ data }) => {
  return (
    <ItemWrapper onClick={(e) => e.stopPropagation()}>
      <EditableForm note={data} />
    </ItemWrapper>
  );
};

const ItemWrapper = styled.div`
  width: 90%;
  height: 90%;
`;
