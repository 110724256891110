import styled from "styled-components";
import { UiPricingPlanCard, UiSmallTabs } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { capitalize } from "@/shared/utils";
import { useState } from "react";
import { useAutoTranslation } from "@/entities";

export const PricingSubscriptionCards = () => {
  const { t } = useAutoTranslation();

  const [activeTabIdx, setActiveTabIdx] = useState(0);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const handleToggleTabs = (idx: number, _: string) => {
    setActiveTabIdx(idx);
  };

  const tabsTitles = ["Monthly", "Annually"];
  return (
    <>
      <TabsWrapper>
        <UiSmallTabs
          titles={tabsTitles}
          activeIdx={activeTabIdx}
          onToggle={handleToggleTabs}
        />
      </TabsWrapper>
      <Wrapper>
        <Item>
          <UiPricingPlanCard
            title={capitalize(t(LANG_KEYS.FREE.key, LANG_KEYS.FREE.value))}
            description={t(
              LANG_KEYS.TRY_FOR_FREE_GET_STARTED_TODAY.key,
              LANG_KEYS.TRY_FOR_FREE_GET_STARTED_TODAY.value,
            )}
            isCurrent
            optionList="FREE"
          />
        </Item>
        <Item>
          <UiPricingPlanCard
            title={capitalize(
              t(LANG_KEYS.ADVANCED.key, LANG_KEYS.ADVANCED.value),
            )}
            description={t(
              LANG_KEYS.PERFECT_FOR_FOCUSED_NOTES_AND_MEETINGS.key,
              LANG_KEYS.PERFECT_FOR_FOCUSED_NOTES_AND_MEETINGS.value,
            )}
            price={activeTabIdx == 0 ? 9 : 81}
            isYearly={activeTabIdx == 1}
            isPopular
            optionList="ADVANCED"
          />
        </Item>
        <Item>
          <UiPricingPlanCard
            title={capitalize(t(LANG_KEYS.PRO.key, LANG_KEYS.PRO.value))}
            description={t(
              LANG_KEYS.UNLIMITED_NOTES_ADVANCED_FEATURES_SERIOUS_PRODUCTIVITY
                .key,
              LANG_KEYS.UNLIMITED_NOTES_ADVANCED_FEATURES_SERIOUS_PRODUCTIVITY
                .value,
            )}
            price={activeTabIdx == 0 ? 24 : 216}
            isYearly={activeTabIdx == 1}
            optionList="PRO"
          />
        </Item>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 32px;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;
const Item = styled.div`
  flex: 1;
`;

const TabsWrapper = styled.div`
  margin-top: 64px;
  margin-bottom: 40px;
  display: flex;
  justify-content: center;
`;
