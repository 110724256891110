export const TRANSCRIBE_SOCKET_COMMANDS = {
  //ERRORS
  USER_ID_NOT_FOUND: "User id not found",
  UNSUPPORTED_FORMAT: "Unsupported file format",
  UNDETECTED_LANGUAGE: "Undetected language",

  //STEP
  DONE_INSPECTING_FILE: "Done Inspecting File",
  DONE_WAV_CONVERTING: "Done Converting to wav format",
  DONE_LANG_DETECTION: "Done Language detection",
  DONE_ORIGINAL_TEXT: "Done Transcribe original text",
  DONE_PARAGRAPH_FORM: "Done Paragraph Form",
  DONE_BULLET_POINTS: "Done Bullet Points",
  DONE_TITLE: "Done Title",
  DONE_FILE: "Done File",
  CHUNK_TRANSCRIBING: "Chunk Transcribing",
  SAVED: "SAVED",
  //MESSAGES
  CONNECTED: "CONNECTED",
};

export const CHAT_SOCKET_COMMANDS = {
  //STEP
  CHAT_RESPONSE: "CHAT_RESPONSE",

  //MESSAGES
  CONNECTED: "CONNECTED",
};
