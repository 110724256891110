import { useQuery } from "@tanstack/react-query";
import {
  notesControllerGetFavorite,
  notesControllerGetMyNotes as queryFn,
  notesControllerGetNotesFromTrash,
} from "@/shared/api/generated.ts";
import { LANG_KEYS, QUERY_KEYS, ROUTER_PATHS } from "@/shared/constants";
import styled from "styled-components";
import { UiHeading, UiNoteCard, UiSpinner } from "@/shared/ui";
import {
  useAutoTranslation,
  useEditNoteStore,
  useRecordOptionsStore,
} from "@/entities";
import { NoteChat, NoteDelete, NoteLike, NotePlay } from "@/features";
import { useLocation, useNavigate } from "react-router-dom";
import { searchFilterFunc } from "@/shared/utils";
import { ImCancelCircle } from "react-icons/im";
import { motion as m } from "framer-motion";

const itemVariants = {
  hidden: { opacity: 0, x: -200 },
  visible: (i) => ({
    opacity: 1,
    x: 0,
    transition: {
      delay: i * 0.05,
      duration: 0.5,
    },
  }),
};

export const NotesList = () => {
  const query = useQuery({
    queryFn,
    queryKey: QUERY_KEYS.NOTES,
  });

  const location = useLocation();
  const navigate = useNavigate();

  useQuery({
    queryFn: notesControllerGetFavorite,
    queryKey: QUERY_KEYS.FAVORITE,
  });
  useQuery({
    queryFn: notesControllerGetNotesFromTrash,
    queryKey: QUERY_KEYS.DELETED,
  });

  const recordOptionsStore = useRecordOptionsStore();
  const { t } = useAutoTranslation();
  const { openEdit } = useEditNoteStore();

  const handleClear = () => {
    navigate(ROUTER_PATHS.HOME, { state: { q: "" } });
  };

  if (query.isLoading) {
    return (
      <div className="flex justify-center">
        <UiSpinner />
      </div>
    );
  }

  if (query.isSuccess && !query.data.length && !location.state?.q) {
    return null;
  }

  if (!query.data) {
    return null;
  }

  return (
    <div>
      <div className="flex justify-center gap-5 items-center mb-5">
        <UiHeading className="!mb-0">
          {location.state && location.state.q
            ? `${t(LANG_KEYS.SEARCH_RESULTS_FOR.key, LANG_KEYS.SEARCH_RESULTS_FOR.value)} "${location.state.q}"`
            : t(LANG_KEYS.SAVED_NOTES.key, LANG_KEYS.SAVED_NOTES.value)}
        </UiHeading>
        {location.state && location.state.q && (
          <button type="button" onClick={handleClear}>
            <ImCancelCircle color="#000" size={25} />
          </button>
        )}
      </div>
      <Wrapper>
        {query.data
          .filter((item) =>
            searchFilterFunc(
              item,
              location.state && location.state.q ? location.state.q : "",
            ),
          )
          .map((item, i) => (
            <m.div
              custom={i}
              initial="hidden"
              animate="visible"
              variants={itemVariants}
              key={`saved-note-${i}`}
            >
              <Item>
                <UiNoteCard
                  {...item}
                  summaryView={recordOptionsStore.summaryView}
                  onExpand={() => {
                    openEdit({ note: item });
                  }}
                  actions={[
                    item.audioUrl ? (
                      <NotePlay audioUrl={item.audioUrl} />
                    ) : undefined,
                    <NoteLike id={item.id} isLiked={item.isFavorite} />,
                    "COPY",
                    <NoteChat id={item.id} />,
                    <NoteDelete id={item.id} />,
                  ]}
                />
              </Item>
            </m.div>
          ))}
      </Wrapper>
    </div>
  );
};

const Wrapper = styled.ul`
  display: flex;
  gap: 15px;
  //width: 90%;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;

  @media screen and (max-width: 991px) {
    flex-direction: column;
    flex-wrap: unset;
  }
`;

const Item = styled.li`
  width: 350px;
  height: 342px;

  @media screen and (max-width: 991px) {
    width: 100%;
  }
`;

// const Title = styled.h2`
//   font-size: 40px;
//   font-weight: 600;
//   color: rgb(33, 37, 41);
//   text-align: center;
//   margin-bottom: 20px;
// `;
