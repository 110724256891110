import styled, { keyframes } from "styled-components";
import { LANG_KEYS } from "@/shared/constants";
import {
  useAutoTranslation,
  useRecordProcessStore,
  useTextNoteStore,
} from "@/entities";
import { AnimatePresence, motion as m } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { TbReload } from "react-icons/tb";
import { CgClose } from "react-icons/cg";
import { formatTime, getBase64FromFile, isSafari } from "@/shared/utils";

const AvailableSeconds = 360;

export const RecordTranscribe = () => {
  const { t } = useAutoTranslation();
  const { transcribing, startTranscribe, fastRecord, finishFastRecord } =
    useRecordProcessStore();
  const { original } = useTextNoteStore();
  const [isRecording, setRecording] = useState(false);
  const [availableSeconds, setAvailableSeconds] = useState<number>(-999);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const chunks = useRef<Blob[]>([]);
  const mediaRecorder = useRef<MediaRecorder | null>();
  const [isPaused, setPaused] = useState(false);

  const handleTogglePausing = () => {
    if (isPaused) {
      timerRef.current = setInterval(() => {
        setAvailableSeconds(handleSecondsInterval);
      }, 1000);
      mediaRecorder.current?.resume();
      setPaused(false);
    } else {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      mediaRecorder.current?.pause();
      setPaused(true);
    }
  };

  const handleSecondsInterval = (prevSeconds: number): number => {
    if (prevSeconds > 0) {
      return prevSeconds - 1;
    } else if (prevSeconds == -999) {
      return -999;
    } else {
      finishRecording();
      return -999;
    }
  };

  const startRecording = async (forceRecord?: boolean) => {
    try {
      if (!forceRecord) {
        if (isRecording || transcribing) return;
      }

      setPaused(false);
      const options = { mimeType: isSafari() ? "audio/mp4" : "audio/webm" };
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      const recorder = new MediaRecorder(stream, options);

      chunks.current = [];

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          chunks.current.push(event.data);
        }
      };

      mediaRecorder.current = recorder;
      mediaRecorder.current.start();

      setRecording(true);
      setAvailableSeconds(AvailableSeconds);
      timerRef.current = setInterval(() => {
        setAvailableSeconds(handleSecondsInterval);
      }, 1000);
    } catch (e) {
      console.log(e);
    }
  };

  const finishRecording = () => {
    setPaused(false);
    if (!mediaRecorder.current) return;
    mediaRecorder.current.onstop = async () => {
      const safariPlatform = isSafari();
      const mimeType = safariPlatform ? "audio/mp4" : "audio/webm";
      const extensionFormat = safariPlatform ? "mp4" : "webm";
      const blob = new Blob(chunks.current, { type: mimeType });
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      const base64 = await getBase64FromFile(blob);
      startTranscribe({ base64, extension: extensionFormat });

      setRecording(false);
      setAvailableSeconds(-999);
    };

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }

    if (mediaRecorder.current?.stream) {
      mediaRecorder.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    chunks.current = [];
  };

  const cancelRecording = () => {
    setPaused(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }

    if (mediaRecorder.current?.stream) {
      mediaRecorder.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    setRecording(false);
    setAvailableSeconds(-999);
  };
  const resetRecording = () => {
    setPaused(false);
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }

    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
    }

    if (mediaRecorder.current?.stream) {
      mediaRecorder.current.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }

    startRecording(true);
  };

  useEffect(() => {
    if (fastRecord) {
      finishFastRecord();
      startRecording();
    }
  }, [fastRecord]);

  return (
    <>
      <AnimatePresence>
        {!transcribing && !original && !isRecording && (
          <m.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ duration: 0.25, ease: "easeOut" }}
            exit={{ scale: 0 }}
          >
            <Wrapper>
              <RecordButton type="button" onClick={() => startRecording(false)}>
                <MicroImg src="/microphone.svg" alt="" />
              </RecordButton>

              <StartLabel>
                {t(
                  LANG_KEYS
                    .TAP_ON_THE_MICROPHONE_BUTTON_ABOVE_TO_START_YOUR_RECORDING
                    .key,
                  LANG_KEYS
                    .TAP_ON_THE_MICROPHONE_BUTTON_ABOVE_TO_START_YOUR_RECORDING
                    .value,
                )}
              </StartLabel>
            </Wrapper>
          </m.div>
        )}
      </AnimatePresence>
      {isRecording && (
        <m.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.45, ease: "easeOut", delay: 0.25 }}
          exit={{ scale: 0 }}
        >
          <ProcessRecordWrapper>
            <TimeWrapper>
              <button type="button" onClick={resetRecording}>
                <TbReload color="#fff" size={36} />
              </button>
              <Time>
                <div>
                  {t(LANG_KEYS.LISTENING.key, LANG_KEYS.LISTENING.value)}...
                </div>
                <h4>{formatTime(availableSeconds)}</h4>
                <button type="button" onClick={handleTogglePausing}>
                  {isPaused
                    ? t(LANG_KEYS.RESUME.key, LANG_KEYS.RESUME.value)
                    : t(LANG_KEYS.PAUSE.key, LANG_KEYS.PAUSE.value)}
                </button>
              </Time>
              <div>
                <button type="button" onClick={cancelRecording}>
                  <CgClose color="#fff" size={36} />
                </button>
              </div>
            </TimeWrapper>

            <StopWrapper>
              <StopCircle type="button" onClick={finishRecording}>
                <StopRectangle />
                {!isPaused && <StopLoader />}
              </StopCircle>
            </StopWrapper>
          </ProcessRecordWrapper>
        </m.div>
      )}
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
`;

const RecordButton = styled.button`
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--main-purple);
  padding: 30px;
  border-radius: 50%;
  transition: all 0.3s ease;

  &:hover {
    background-color: #6366f1;
  }

  &:active {
    transform: scale(0.75);
  }

  &:hover img {
    transform: scale(1.25);
  }
`;

const MicroImg = styled.img`
  transition: all 0.3s ease;
  width: 42px;
  height: 42px;
`;

const StartLabel = styled.div`
  font-size: 1.125rem;
  text-align: center;
  color: rgb(75, 85, 99);
`;

const ProcessRecordWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const TimeWrapper = styled.div`
  background-color: var(--main-purple);
  padding: 20px;
  border-radius: 20px;
  width: 600px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const Time = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: #fff;
  align-items: center;
  font-size: 18px;

  h4 {
    font-size: 50px;
    font-weight: 700;
    letter-spacing: 3px;
  }

  button {
    text-decoration: underline;
  }
`;

const StopWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StopCircle = styled.button`
  background-color: var(--main-purple);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 100px;
  height: 100px;
  position: relative;
`;

const StopRectangle = styled.div`
  width: 21px;
  height: 21px;
  background-color: #fff;
`;

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const StopLoader = styled.div`
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  border: 7px solid transparent;
  border-top-color: blue;
  border-right-color: blue;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
  z-index: 0;
`;
