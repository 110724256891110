import { useTranslation } from "react-i18next";
import { newDictionaryKey } from "@/shared/api/dictionary.ts";

export function useAutoTranslation() {
  const i18n = useTranslation();

  const translate = (
    key: string,
    value: string,
    data?: Record<string, string>,
  ) => {
    const translation = i18n.t(key);
    if (translation == key) {
      newDictionaryKey({ key, value }).then(() => {
        console.log(`informed API about key ${key}`);
      });
    }

    if (!!data && Object.values(data).length) {
      console.log({ templateStringData: data, key });
    }

    return translation;
  };

  return { t: translate };
}
