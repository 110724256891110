import {
  LANG_KEYS,
  QUERY_KEYS,
  TRANSCRIBE_SOCKET_COMMANDS,
} from "@/shared/constants";
import { markDone, StepType } from "./common.ts";
import {
  useEditNoteStore,
  useRecordProcessStore,
  useTextNoteStore,
  useUserStore,
} from "@/entities";
import { useEffect, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { io, Socket } from "socket.io-client";
import { Note } from "@/shared/api/generated.ts";

const ALL_STEPS: StepType[] = [
  {
    data: LANG_KEYS.CONNECTING_TO_SERVER,
    done: false,
    text: "",
  },

  {
    data: LANG_KEYS.GET_PARAGRAPH_FORM,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_BULLET_POINTS,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.GET_TITLE,
    done: false,
    text: "",
  },
  {
    data: LANG_KEYS.SAVE,
    done: false,
    text: "",
  },
];

export function useTextProcess() {
  const url = import.meta.env.VITE_TEXT_SOCKET_URL;
  const { original, clearOriginal } = useTextNoteStore();
  const { transcribing } = useRecordProcessStore();
  const [currentIdx, setCurrentIdx] = useState(0);
  const [steps, setSteps] = useState<StepType[]>(ALL_STEPS);
  const { access_token } = useUserStore();
  const { openEdit } = useEditNoteStore();
  const queryClient = useQueryClient();

  const socket = useRef<Socket>(
    io(url, {
      autoConnect: false,
      auth: {
        token: access_token,
      },
    }),
  ).current;

  useEffect(() => {
    if (original && !transcribing) {
      window.scrollTo({ top: 0, behavior: "smooth" });
      setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
      socket.connect();

      const setupListeners = () => {
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.CONNECTED, () => {
          socket.emit("start", { original });
          setCurrentIdx(1);
          setSteps((prev) => {
            return markDone(prev, 0, "");
          });
        });

        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.DONE_PARAGRAPH_FORM,
          ({ data }: any) => {
            setCurrentIdx(2);
            setSteps((prev) => {
              return markDone(prev, 1, data);
            });
          },
        );
        socket.on(
          TRANSCRIBE_SOCKET_COMMANDS.DONE_BULLET_POINTS,
          ({ data }: any) => {
            setCurrentIdx(3);
            setSteps((prev) => {
              return markDone(prev, 2, data);
            });
          },
        );
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.DONE_TITLE, ({ data }: any) => {
          setCurrentIdx(4);
          setSteps((prev) => {
            return markDone(prev, 3, data);
          });
        });
        socket.on(TRANSCRIBE_SOCKET_COMMANDS.SAVED, ({ data }: any) => {
          const notes = JSON.parse(data) as { all: Note[]; newNote: Note };
          queryClient.setQueryData(QUERY_KEYS.NOTES, () => notes.all);
          setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
          setCurrentIdx(0);
          clearOriginal();
          openEdit({ note: notes.newNote });
        });

        socket.on("disconnect", () => {
          setSteps(JSON.parse(JSON.stringify(ALL_STEPS)));
          setCurrentIdx(0);
          socket.removeAllListeners();
        });
      };

      setupListeners();
    }
  }, [original]);

  return { steps, currentIdx };
}
