import { Link, useLocation } from "react-router-dom";
import {
  UiAlert,
  UiAnchor,
  UiButton,
  UiDropMenu,
  UiLink,
  UiLogo,
  UiShow,
} from "@/shared/ui";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import { capitalize, useTemplateStrings } from "@/shared/utils";
import {
  useAutoTranslation,
  useRecordOptionsModalStore,
  useRecordOptionsStore,
  useSearchNotesStore,
  useUserStore,
} from "@/entities";
import { ChangeLang, FileTranscribe, LeaveSuggestion } from "@/features";
import { RxHamburgerMenu } from "react-icons/rx";
import { useEffect, useState } from "react";
import { AnimatePresence, motion as m } from "framer-motion";
import { IoCloseOutline } from "react-icons/io5";
import styles from "./styles.module.scss";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { userControllerResendConfirmationEmail } from "@/shared/api/generated.ts";
import { toast } from "react-toastify";
import { LANG_KEYS, QUERY_KEYS, ROUTER_PATHS } from "@/shared/constants";

export const UiMainHeader = () => {
  const { t } = useAutoTranslation();
  const templateStrings = useTemplateStrings();
  const queryClient = useQueryClient();
  const { logout, email, isEmailConfirmed, login } = useUserStore();
  const recordOptionsModalStore = useRecordOptionsModalStore();
  const location = useLocation();
  const searchNotesStore = useSearchNotesStore();
  const { invalidateOptions } = useRecordOptionsStore();
  const resendEmailMutation = useMutation({
    mutationFn: userControllerResendConfirmationEmail,
    async onSuccess({ message, isResent, access_token }) {
      toast.info(message);
      if (!isResent && access_token) {
        login(access_token);
      }
    },
  });

  const [mobileMenuOpen, setMobileMenu] = useState(false);

  const openMenu = () => {
    // BodyNoScroll.Enable();
    setMobileMenu(true);
  };

  const closeMenu = () => {
    // BodyNoScroll.Disable();
    setMobileMenu(false);
  };

  const openRecordOptionsModal = () => {
    if (mobileMenuOpen) {
      setMobileMenu(false);
    }
    recordOptionsModalStore.showModal();
  };

  const handleLogout = () => {
    logout();
    setMobileMenu(false);
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
    queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
    invalidateOptions();
  };

  useEffect(() => {
    closeMenu();
  }, [location]);

  return (
    <>
      {!!email && !isEmailConfirmed && (
        <AnimatePresence mode="wait">
          <m.div
            initial={{ left: "-100%" }}
            animate={{ left: "0" }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            exit={{ left: "100%" }}
            className="relative"
          >
            <UiAlert
              rightChildren={
                <UiButton
                  onClick={() => resendEmailMutation.mutate({})}
                  variant="white"
                  loading={resendEmailMutation.isPending}
                >
                  <div>
                    {t(
                      LANG_KEYS.RESEND_CONFIRMATION_EMAIL.key,
                      LANG_KEYS.RESEND_CONFIRMATION_EMAIL.value,
                    )}
                  </div>
                </UiButton>
              }
            >
              <div className="!inline whitespace-pre-wrap">
                {templateStrings.weSentYouAnActivationLinkToEmailPleaseCheckYourSpamFolder(
                  email,
                )}
              </div>
            </UiAlert>
          </m.div>
        </AnimatePresence>
      )}
      <Header>
        <div className="theContainer">
          <Wrapper>
            <Link to={ROUTER_PATHS.HOME} className="mr-5">
              <UiLogo />
            </Link>

            <Navigation>
              <NavigationList>
                <FileTranscribe>
                  <li>
                    <UiLink linkHover={false} className="text-center">
                      {capitalize(
                        t(
                          LANG_KEYS.UPLOAD_AUDIO_FILE.key,
                          LANG_KEYS.UPLOAD_AUDIO_FILE.value,
                        ),
                      )}
                    </UiLink>
                  </li>
                </FileTranscribe>
                <li>
                  <button type="button" onClick={openRecordOptionsModal}>
                    <UiLink className="text-center">
                      {capitalize(
                        t(LANG_KEYS.SETTINGS.key, LANG_KEYS.SETTINGS.value),
                      )}
                    </UiLink>
                  </button>
                </li>
                <li>
                  <Link to={ROUTER_PATHS.PRICING}>
                    <UiLink className="text-center">
                      {capitalize(
                        t(LANG_KEYS.PRICING.key, LANG_KEYS.PRICING.value),
                      )}
                    </UiLink>
                  </Link>
                </li>

                <li>
                  <ChangeLang />
                </li>

                <button type="button" onClick={searchNotesStore.showModal}>
                  <li>
                    <div className="flex items-center gap-2 cursor-pointer">
                      <FaSearch />
                      <UiLink linkHover={false} className="text-center">
                        {capitalize(
                          t(LANG_KEYS.SEARCH.key, LANG_KEYS.SEARCH.value),
                        )}
                      </UiLink>
                    </div>
                  </li>
                </button>
              </NavigationList>
            </Navigation>

            <UiShow>
              <UiShow.When isTrue={!email}>
                <LoginWrapper>
                  <SignIn>
                    <Link to={ROUTER_PATHS.AUTH_SIGN_IN}>
                      <UiLink>
                        {capitalize(
                          t(LANG_KEYS.LOGIN.key, LANG_KEYS.LOGIN.value),
                        )}
                      </UiLink>
                    </Link>
                  </SignIn>
                  <Link to={ROUTER_PATHS.AUTH_SIGN_UP}>
                    <UiButton type="button" variant="main">
                      <div>
                        {capitalize(
                          t(LANG_KEYS.SIGN_UP.key, LANG_KEYS.SIGN_UP.value),
                        )}
                      </div>
                    </UiButton>
                  </Link>
                  <Hamburger type="button" onClick={openMenu}>
                    <RxHamburgerMenu size={25} />
                  </Hamburger>
                </LoginWrapper>
              </UiShow.When>
              <UiShow.Else>
                <div className={styles.loggedMenu}>
                  <div className={styles.loggedMenu__pc}>
                    <UiDropMenu
                      text={capitalize(
                        t(LANG_KEYS.ACCOUNT.key, LANG_KEYS.ACCOUNT.value),
                      )}
                      contentPosition="left"
                    >
                      <AccountMenuItem
                        $showBorder
                        $cursorDefault
                        $paddingY="16px"
                      >
                        {capitalize(
                          t(
                            LANG_KEYS.SIGNED_IN_AS.key,
                            LANG_KEYS.SIGNED_IN_AS.value,
                          ),
                        )}
                        <br />
                        {email}
                      </AccountMenuItem>
                      <Link to={ROUTER_PATHS.PRICING}>
                        <AccountMenuItem $hoverEffect>
                          {capitalize(
                            t(
                              LANG_KEYS.MANAGE_MY_SUBSCRIPTION.key,
                              LANG_KEYS.MANAGE_MY_SUBSCRIPTION.value,
                            ),
                          )}
                        </AccountMenuItem>
                      </Link>
                      <Link to={ROUTER_PATHS.ACCOUNT_SETTINGS}>
                        <AccountMenuItem $showBorder $hoverEffect>
                          {capitalize(
                            t(
                              LANG_KEYS.ACCOUNT_SETTINGS.key,
                              LANG_KEYS.ACCOUNT_SETTINGS.value,
                            ),
                          )}
                        </AccountMenuItem>
                      </Link>
                      <Link to={ROUTER_PATHS.FAVORITE_NOTES}>
                        <AccountMenuItem $showBorder $hoverEffect>
                          {capitalize(
                            t(
                              LANG_KEYS.FAVORITE_NOTES.key,
                              LANG_KEYS.FAVORITE_NOTES.value,
                            ),
                          )}
                        </AccountMenuItem>
                      </Link>
                      <Link to={ROUTER_PATHS.DELETED_NOTES}>
                        <AccountMenuItem $showBorder $hoverEffect>
                          {capitalize(
                            t(
                              LANG_KEYS.DELETED_NOTES.key,
                              LANG_KEYS.DELETED_NOTES.value,
                            ),
                          )}
                        </AccountMenuItem>
                      </Link>
                      <AccountMenuItem onClick={handleLogout} $hoverEffect>
                        {capitalize(
                          t(LANG_KEYS.SIGN_OUT.key, LANG_KEYS.SIGN_OUT.value),
                        )}
                      </AccountMenuItem>
                    </UiDropMenu>
                  </div>
                  <div className={styles.loggedMenu__mobile}>
                    <Hamburger type="button" onClick={openMenu}>
                      <RxHamburgerMenu size={25} />
                    </Hamburger>
                  </div>
                </div>
              </UiShow.Else>
            </UiShow>
          </Wrapper>
        </div>
      </Header>

      <AnimatePresence mode="wait">
        {mobileMenuOpen && (
          <m.div
            onClick={closeMenu}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.3, ease: "easeOut" }}
            exit={{ opacity: 0 }}
            className="fixed h-screen w-screen top-0 left-0  bg-black bg-opacity-50 flex justify-end z-[100]"
          >
            <m.div
              onClick={(e) => e.stopPropagation()}
              initial={{ right: "-100%" }}
              animate={{ right: "0" }}
              transition={{ duration: 0.3, ease: "easeOut" }}
              exit={{ right: "-100%" }}
              className={styles.mobileMenuPaper}
            >
              <div className="flex items-center justify-between mb-6">
                <Link to={ROUTER_PATHS.HOME}>
                  <UiLogo fontSize={18} />
                </Link>

                <button type="button" onClick={closeMenu}>
                  <IoCloseOutline size={25} />
                </button>
              </div>

              <ul className="flex flex-col gap-4">
                {email && (
                  <li className="py-5 border-t-[1px] border-b-[1px] border-black">
                    <UiLink
                      fontSize={16}
                      linkHover={false}
                      className="!cursor-default !px-[16px]"
                    >
                      {capitalize(
                        t(
                          LANG_KEYS.SIGNED_IN_AS.key,
                          LANG_KEYS.SIGNED_IN_AS.value,
                        ),
                      )}
                      <br />
                      {email}
                    </UiLink>
                  </li>
                )}
                <FileTranscribe btnClassname="w-full">
                  <li>
                    <UiLink fontSize={16} box linkHover={false}>
                      {capitalize(
                        t(
                          LANG_KEYS.UPLOAD_AUDIO_FILE.key,
                          LANG_KEYS.UPLOAD_AUDIO_FILE.value,
                        ),
                      )}
                    </UiLink>
                  </li>
                </FileTranscribe>
                <li>
                  <button
                    type="button"
                    className="w-full"
                    onClick={openRecordOptionsModal}
                  >
                    <UiLink fontSize={16} box linkHover={false}>
                      {capitalize(
                        t(LANG_KEYS.SETTINGS.key, LANG_KEYS.SETTINGS.value),
                      )}
                    </UiLink>
                  </button>
                </li>
                <li>
                  <Link to={ROUTER_PATHS.PRICING}>
                    <UiLink fontSize={16} box>
                      {capitalize(
                        t(
                          LANG_KEYS.MANAGE_MY_SUBSCRIPTION.key,
                          LANG_KEYS.MANAGE_MY_SUBSCRIPTION.value,
                        ),
                      )}
                    </UiLink>
                  </Link>
                </li>
                {email && (
                  <li>
                    <Link to={ROUTER_PATHS.ACCOUNT_SETTINGS}>
                      <UiLink fontSize={16} box>
                        {capitalize(
                          t(
                            LANG_KEYS.ACCOUNT_SETTINGS.key,
                            LANG_KEYS.ACCOUNT_SETTINGS.value,
                          ),
                        )}
                      </UiLink>
                    </Link>
                  </li>
                )}
                {email && (
                  <Link to={ROUTER_PATHS.FAVORITE_NOTES}>
                    <li>
                      <UiLink fontSize={16} box>
                        {capitalize(
                          t(
                            LANG_KEYS.FAVORITE_NOTES.key,
                            LANG_KEYS.FAVORITE_NOTES.value,
                          ),
                        )}
                      </UiLink>
                    </li>
                  </Link>
                )}

                {email && (
                  <Link to={ROUTER_PATHS.DELETED_NOTES}>
                    <li>
                      <UiLink fontSize={16} box>
                        {capitalize(
                          t(
                            LANG_KEYS.DELETED_NOTES.key,
                            LANG_KEYS.DELETED_NOTES.value,
                          ),
                        )}
                      </UiLink>
                    </li>
                  </Link>
                )}
                <Link to={ROUTER_PATHS.PRICING}>
                  <li>
                    <UiLink fontSize={16} box>
                      {capitalize(
                        t(LANG_KEYS.PRICING.key, LANG_KEYS.PRICING.value),
                      )}
                    </UiLink>
                  </li>
                </Link>
                <li>
                  <button
                    type="button"
                    className="w-full"
                    onClick={searchNotesStore.showModal}
                  >
                    <UiLink fontSize={16} box linkHover={false}>
                      {capitalize(
                        t(LANG_KEYS.SEARCH.key, LANG_KEYS.SEARCH.value),
                      )}
                    </UiLink>
                  </button>
                </li>
                <li>
                  <ChangeLang variant="selectable" />
                </li>
                <li className="py-5 border-t-[1px] border-b-[1px] border-black">
                  {!email ? (
                    <Link to={ROUTER_PATHS.AUTH_SIGN_IN}>
                      <UiLink fontSize={16} box linkHover={false}>
                        {capitalize(
                          t(LANG_KEYS.LOGIN.key, LANG_KEYS.LOGIN.value),
                        )}
                      </UiLink>
                    </Link>
                  ) : (
                    <button
                      onClick={handleLogout}
                      type="button"
                      className="w-full"
                    >
                      <UiLink fontSize={16} box linkHover={false}>
                        {capitalize(
                          t(LANG_KEYS.SIGN_OUT.key, LANG_KEYS.SIGN_OUT.value),
                        )}
                      </UiLink>
                    </button>
                  )}
                </li>
                <li>
                  <a href={ROUTER_PATHS.PRIVACY_POLICY} target="_blank">
                    <UiAnchor hoverEffect>
                      {t(
                        LANG_KEYS.PRIVACY_POLICY.key,
                        LANG_KEYS.PRIVACY_POLICY.value,
                      )}
                    </UiAnchor>
                  </a>
                </li>
                <li>
                  <a href={ROUTER_PATHS.TERMS_OF_SERVICE} target="_blank">
                    <UiAnchor hoverEffect>
                      {t(
                        LANG_KEYS.TERMS_OF_SERVICE.key,
                        LANG_KEYS.TERMS_OF_SERVICE.value,
                      )}
                    </UiAnchor>
                  </a>
                </li>
                <li onClick={closeMenu}>
                  <LeaveSuggestion triggerMenu />
                </li>
              </ul>
            </m.div>
          </m.div>
        )}
      </AnimatePresence>
    </>
  );
};

const Header = styled.header`
  padding: 24px 32px;

  @media screen and (max-width: 575px) {
    padding: 12px;
  }
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Navigation = styled.nav`
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const NavigationList = styled.ul`
  display: flex;
  align-items: center;
  gap: 50px;
`;

const LoginWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 575px) {
    gap: 8px;
  }
`;

const SignIn = styled.div`
  @media screen and (max-width: 1080px) {
    display: none;
  }
`;

const Hamburger = styled.button`
  display: none;

  @media screen and (max-width: 1080px) {
    display: flex;
  }
`;

const AccountMenuItem = styled.div<{
  $showBorder?: boolean;
  $hoverEffect?: boolean;
  $cursorDefault?: boolean;
  $paddingY?: string;
}>`
  padding-left: 24px;
  padding-right: 24px;
  padding-top: ${(props) => (props.$paddingY ? props.$paddingY : "8px")};
  padding-bottom: ${(props) => (props.$paddingY ? props.$paddingY : "8px")};
  cursor: ${(props) => (props.$cursorDefault ? "default" : "pointer")};
  border-bottom-width: 1px;
  font-size: 14px;
  font-weight: 400;
  border-color: ${({ $showBorder }) =>
    $showBorder ? "rgb(229, 231, 235)" : "transparent"};

  ${(props) =>
    props.$hoverEffect &&
    `
	&:hover {
		color: #0056b3;
		text-decoration: underline;
	}
  `}
`;
