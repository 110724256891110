import styled from "styled-components";
import { ChangeEmail } from "@/features";
import { capitalize } from "@/shared/utils";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { UiButton, UiInput, UiShow, UiSpinner, UiTextarea } from "@/shared/ui";
import { useAccountForm } from "./model.ts";
import { Link } from "react-router-dom";
import { useAutoTranslation, useDeleteAccountModalStore } from "@/entities";

export const AccountSettings = () => {
  const { t } = useAutoTranslation();
  const { accountQuery, register, isLoading, handleSubmit } = useAccountForm();

  const deleteAccountModalStore = useDeleteAccountModalStore();

  return (
    <Card>
      <Title>
        {capitalize(
          t(
            LANG_KEYS.MANAGE_YOUR_ACCOUNT.key,
            LANG_KEYS.MANAGE_YOUR_ACCOUNT.value,
          ),
        )}
      </Title>
      <UiShow>
        <UiShow.When isTrue={accountQuery.isLoading && !accountQuery.isSuccess}>
          <LoadingWrapper>
            <UiSpinner />
          </LoadingWrapper>
        </UiShow.When>
        <UiShow.When isTrue={accountQuery.isSuccess}>
          <div>
            <ChangeEmail />
            <form onSubmit={handleSubmit}>
              <div className="flex flex-col gap-6 my-3">
                {/*<div>[TODO] CONNECT/DISCONNECT WITH NOTION</div>*/}
                {/*<div>[TODO] CONNECT WITH ZAPIER</div>*/}
                {/*<div>[TODO] ZAPIER TEMPLATE</div>*/}
                <div className="flex items-center">
                  {/*<div className="flex-1">[TODO] ZAPIER API TOKEN</div>*/}
                  <div className="flex-1">
                    <UiInput
                      label={t(
                        LANG_KEYS.SEND_NOTES_TO_ANY_APP_WITH_WEBHOOK.key,
                        LANG_KEYS.SEND_NOTES_TO_ANY_APP_WITH_WEBHOOK.value,
                      )}
                      placeholder={t(
                        LANG_KEYS.WEBHOOK_URL.key,
                        LANG_KEYS.WEBHOOK_URL.value,
                      )}
                      {...register("webhookUrl")}
                    />
                  </div>
                </div>
                <div>
                  <UiTextarea
                    placeholder={t(
                      LANG_KEYS.FOR_EXAMPLE_CUSTOM_VOCABULARY.key,
                      LANG_KEYS.FOR_EXAMPLE_CUSTOM_VOCABULARY.value,
                    )}
                    label={t(
                      LANG_KEYS
                        .ADD_CUSTOM_VOCABULARY_TO_BOOST_THE_ACCURACY_FOR_PROPER_NAMES_JARGON_ACRONYMS_AND_UNIQUE_NAME_SPELLINGS
                        .key,
                      LANG_KEYS
                        .ADD_CUSTOM_VOCABULARY_TO_BOOST_THE_ACCURACY_FOR_PROPER_NAMES_JARGON_ACRONYMS_AND_UNIQUE_NAME_SPELLINGS
                        .value,
                    )}
                    {...register("vocabulary")}
                  />
                  <p className="text-gray-600 text-sm mt-3">
                    {t(
                      LANG_KEYS
                        .TYPE_YOUR_VOCABULARY_IN_THE_FIELD_ABOVE_DIVIDED_BY_COMMAS
                        .key,
                      LANG_KEYS
                        .TYPE_YOUR_VOCABULARY_IN_THE_FIELD_ABOVE_DIVIDED_BY_COMMAS
                        .value,
                    )}
                  </p>
                </div>
              </div>

              <LineDivider />
              <Footer>
                <FooterBtns>
                  <UiButton
                    type="button"
                    variant="danger"
                    className="w-full"
                    onClick={deleteAccountModalStore.showModal}
                  >
                    <div>
                      {t(
                        LANG_KEYS.DELETE_MY_ACCOUNT.key,
                        LANG_KEYS.DELETE_MY_ACCOUNT.value,
                      )}
                    </div>
                  </UiButton>
                </FooterBtns>
                <FooterBtns>
                  <Link to={ROUTER_PATHS.HOME} className="w-full">
                    <UiButton variant="white" type="button" className="w-full">
                      <div>
                        {capitalize(
                          t(LANG_KEYS.CANCEL.key, LANG_KEYS.CANCEL.value),
                        )}
                      </div>
                    </UiButton>
                  </Link>
                  <UiButton
                    variant="main"
                    className="w-full"
                    type="submit"
                    loading={isLoading}
                  >
                    <div>
                      {capitalize(t(LANG_KEYS.SAVE.key, LANG_KEYS.SAVE.value))}
                    </div>
                  </UiButton>
                </FooterBtns>
              </Footer>
            </form>
          </div>
        </UiShow.When>
      </UiShow>
    </Card>
  );
};

const Card = styled.div`
  background-color: #fff;
  border-radius: 15px;
  border: 2px solid #dfdddd;
  padding: 2rem;

  @media screen and (max-width: 991px) {
    padding: 0;
    border-color: transparent;
  }
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 2.5rem;
`;

const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 575px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
`;

const LineDivider = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
  background-color: rgba(17, 24, 39, 0.1);
  height: 1px;
`;

const FooterBtns = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;

  @media screen and (max-width: 575px) {
    width: 100%;
  }
`;

const LoadingWrapper = styled.div`
  min-height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
