export const LANG_KEYS = {
  EMAIL_ADDRESS: {
    key: "emailAddress",
    value: "Email address",
  },
  REQUIRED: {
    key: "requiredKey",
    value: "Required",
  },
  INVALID_EMAIL_FORMAT: {
    key: "invalidEmail",
    value: "Invalid email format",
  },
  FORGOT_PASSWORD: {
    key: "forgotPassword",
    value: "Forgot password?",
  },
  SIGN_IN: {
    key: "loginKey",
    value: "Login",
  },
  PASSWORDS_DONT_MATCH: {
    key: "passwordsDontMatch",
    value: "Passwords don't match",
  },
  RESET_YOUR_PASSWORD: {
    key: "resetYourPassword",
    value: "Reset your password",
  },
  FOR_EMAIL: {
    key: "forEmail",
    value: "For email",
  },
  PASSWORD: {
    key: "passwordKey",
    value: "Password",
  },
  CONFIRM_PASSWORD: {
    key: "confirmPassword",
    value: "Confirm password",
  },
  CONFIRM: {
    key: "confirmKey",
    value: "Confirm",
  },
  CHECK_YOUR_EMAIL_TO_UPDATE_YOUR_PASSWORD: {
    key: "checkYourEmailToUpdateYourPassword",
    value: "Check your email to update your password",
  },
  SEND_EMAIL: {
    key: "sendEmailKey",
    value: "Send email",
  },
  SIGN_UP: {
    key: "signUp",
    value: "join",
  },
  OR_CONTINUE_WITH: {
    key: "orContinueWith",
    value: "Or continue with",
  },
  CHANGE: {
    key: "changeKey",
    value: "Change",
  },
  CHANGE_YOUR_EMAIL: {
    key: "changeYourEmail",
    value: "Change your email",
  },
  ARE_YOU_SURE_THAT_YOU_WANT_TO_CHANGE_YOUR_EMAIL: {
    key: "areYouSureThatYouWantToChangeYourEmail",
    value: "Are you sure that you want to change your email?",
  },
  CANCEL: {
    key: "cancelKey",
    value: "Cancel",
  },
  ENTER_CONFIRMATION_CODE: {
    key: "enterConfirmationCode",
    value: "Enter Confirmation Code",
  },
  WE_HAVE_SENT_A_CONFIRMATION_CODE_TO_YOUR_CURRENT_EMAIL: {
    key: "weHaveSentAConfirmationCodeToYourCurrentEmail",
    value: "We have sent a confirmation code to your current email",
  },
  CONFIRMATION_CODE: {
    key: "confirmationCode",
    value: "Confirmation code",
  },
  AN_INVALID_VALUE_WAS_ENTERED_CHECK_THAT_THE_CONFIRMATION_CODE_IS_ENTERED_CORRECTLY_AND_TRY_AGAIN:
    {
      key: "anInvalidValueWasEnteredCheckThatTheConfirmationCodeIsEnteredCorrectlyAndTryAgain",
      value:
        "An invalid value was entered. Check that the confirmation code is entered correctly and try again.",
    },
  ENTER_NEW_EMAIL: {
    key: "enterNewEmail",
    value: "Enter New Email",
  },
  YOUR_ACCOUNT_WILL_BE_LINKED_TO_THE_SPECIFIED_EMAIL: {
    key: "yourAccountWillBeLinkedToTheSpecifiedEmail",
    value: "Your account will be linked to the specified email",
  },
  NEW_EMAIL: {
    key: "newEmail",
    value: "New email",
  },
  SET_NEW_EMAIL: {
    key: "setNewEmail",
    value: "Set new email",
  },
  FINAL_STEP: {
    key: "finalStep",
    value: "Final Step!",
  },
  A_CONFIRMATION_CODE_HAS_BEEN_SENT_TO_YOUR_NEWLY_PROVIDED_EMAIL_ADDRESS_PLEASE_BE_AWARE_THAT_THIS_ACTION_ONCE_COMPLETED_CANNOT_BE_REVERSED:
    {
      key: "aConfirmationCodeHasBeenSentToYourNewlyProvidedEmailAddressPleaseBeAwareThatThisActionOnceCompletedCannotBeReversed",
      value:
        "A confirmation code has been sent to your newly provided email address. Please be aware that this action, once completed, cannot be reversed.",
    },
  TAP_ON_THE_MICROPHONE_BUTTON_ABOVE_TO_START_YOUR_RECORDING: {
    key: "tapOnTheMicrophoneButtonAboveToStartYourRecording",
    value: "Tap on the microphone button above to start your recording",
  },
  CUSTOM_SUMMARY_STYLE_NAME: {
    key: "customSummaryStyleName",
    value: "Custom summary style name",
  },
  FOR_EXAMPLE_HIGHLIGHTS_AND_KEY_TAKEAWAYS: {
    key: "forExampleHighlightsAndKeyTakeaways",
    value: "For example: Highlights and Key Takeaways",
  },
  CUSTOM_SUMMARY_STYLE_PROMPT: {
    key: "customSummaryStylePrompt",
    value: "Custom summary style prompt",
  },
  FOR_EXAMPLE_CHERRY_PICK_THE_MOST_STRIKING_ESSENTIAL_ELEMENTS_FROM_THE_RECEIVED_TEXT_CONCENTRATE_ON_SYNTHESIZING_THESE_INTO_POWERFUL_HIGHLIGHTS_AND_TAKEAWAYS_THAT_STAND_OUT_THE_SUMMARY_SHOULD_BE_A_LIGHTHOUSE_GUIDING_READERS_TO_THE_PIVOTAL_POINTS_PAINTED_VIVIDLY_AND_ENGAGINGLY:
    {
      key: "forExampleCustomSummaryStylePromptPlaceholder",
      value:
        "For example: Cherry-pick the most striking, essential elements from the received text. Concentrate on synthesizing these into powerful highlights and takeaways that stand out. The summary should be a lighthouse, guiding readers to the pivotal points, painted vividly and engagingly.",
    },
  ADD: {
    key: "addKey",
    value: "add",
  },
  EDIT: {
    key: "editKey",
    value: "edit",
  },
  DELETE: {
    key: "deleteKey",
    value: "delete",
  },
  SAVE: {
    key: "saveKey",
    value: "save",
  },
  NUMBER_OF_SPEAKERS: {
    key: "numberOfSpeakers",
    value: "Number of speakers",
  },
  SELECT_THE_LANGUAGE_IN_WHICH_YOUR_NOTES_WILL_BE_DISPLAYED: {
    key: "selectTheLanguageInWhichYourNotesWillBeDisplayed",
    value: "Select the language in which your notes will be displayed",
  },
  OUTPUT_NOTES_LANGUAGE: {
    key: "outputNotesLanguage",
    value: "Output notes language",
  },
  DEFAULT_SUMMARY_STYLES: {
    key: "DefaultSummaryStyles",
    value: "Default summary styles",
  },
  SELECT_THE_STYLE_IN_WHICH_YOUD_LIKE_TO_VIEW_YOUR_NOTES: {
    key: "selectTheStyleInWhichYoudLikeToViewYourNotes",
    value: "Select the style in which you'd like to view your notes",
  },
  CHOOSE_SUMMARY_STYLE: {
    key: "chooseSummaryStyle",
    value: "Choose summary style",
  },
  CUSTOM_SUMMARY_STYLES: {
    key: "customSummaryStyles",
    value: "Custom summary styles",
  },
  ADD_YOUR_PROMPT: {
    key: "addYourPrompt",
    value: "Add your prompt",
  },
  DEFAULT_SUMMARY_VIEW: {
    key: "defaultSummaryView",
    value: "Default summary view",
  },
  CHOOSE_SUMMARY_LENGTH: {
    key: "chooseSummaryLength",
    value: "Choose summary length",
  },
  EMAIL_CONFIRMED: {
    key: "emailConfirmed",
    value: "Email confirmed",
  },
  MAIN_PAGE: {
    key: "mainPage",
    value: "Main page",
  },
  SUPERCHARGE_YOUR_PRODUCTIVITY: {
    key: "superchargeYourProductivity",
    value: "Supercharge your productivity",
  },
  TRANSFORM_YOUR_THOUGHTS_INTO_CLEAR_TEXT_NOTES: {
    key: "transformYourThoughtsIntoClearTextNotes",
    value: "Transform Your Thoughts into Clear Text Notes",
  },
  NOT_A_MEMBER: {
    key: "notAMember",
    value: "Not a member?",
  },
  SIGN_UP_NOW: {
    key: "signUpNow",
    value: "Sign up now",
  },
  SIGN_IN_TO_YOUR_ACCOUNT: {
    key: "signInToYourAccount",
    value: "Sign in to your account",
  },
  ALREADY_HAVE_AN_ACCOUNT: {
    key: "alreadyHaveAnAccount",
    value: "Already have an account?",
  },
  MANAGE_YOUR_ACCOUNT: {
    key: "manageYourAccount",
    value: "Manage your account",
  },
  WE_SENT_YOU_AN_ACTIVATION_LINK_TO_ADDRESS: {
    key: "weSentYouAnActivationLinkToKey",
    value: "We sent you an activation link to address",
  },
  PLEASE_CHECK_YOUR_SPAM_FOLDER: {
    key: "pleaseCheckYourSpamFolderKey",
    value: "Please check your spam folder",
  },
  UPLOAD_AUDIO_FILE: {
    key: "uploadAudioFile",
    value: "Upload audio file",
  },
  SETTINGS: {
    key: "settingsKey",
    value: "Settings",
  },
  PRICING: {
    key: "pricingKey",
    value: "Pricing",
  },
  SEARCH: {
    key: "searchKey",
    value: "search",
  },
  LOGIN: {
    key: "loginKey2",
    value: "Login",
  },
  SUMMARY_LANGUAGE: {
    key: "summaryLanguage",
    value: "Summary language",
  },
  CUSTOMIZE_YOUR_NOTE_TAKING_EXPERIENCE_BY_ADJUSTING_THE_APP_SETTINGS_TO_YOUR_PREFERENCE:
    {
      key: "optionsSubtitle",
      value:
        "Customize your note-taking experience by adjusting the app settings to your preference.",
    },
  RESEND_CONFIRMATION_EMAIL: {
    key: "resendConfirmationEmail",
    value: "Resend email again",
  },
  SEND_NOTES_TO_ANY_APP_WITH_WEBHOOK: {
    key: "sendNotesToAnyAppWithWebhook",
    value: "Send notes to any app with Webhook",
  },
  WEBHOOK_URL: {
    key: "webhookUrl",
    value: "Webhook url",
  },
  FOR_EXAMPLE_CUSTOM_VOCABULARY: {
    key: "forExampleCustomVocabulary",
    value: "For example: Elon Musk, AudioNotes.ai, YMCA, etc.",
  },
  ADD_CUSTOM_VOCABULARY_TO_BOOST_THE_ACCURACY_FOR_PROPER_NAMES_JARGON_ACRONYMS_AND_UNIQUE_NAME_SPELLINGS:
    {
      key: "addCustomVocabularyToBoostTheAccuracyForProperNamesJargonAcronymsAndUniqueNameSpellings",
      value:
        "Add custom vocabulary to boost the accuracy for proper names, jargon, acronyms, and unique name spellings.",
    },
  TYPE_YOUR_VOCABULARY_IN_THE_FIELD_ABOVE_DIVIDED_BY_COMMAS: {
    key: "typeYourVocabularyInTheFieldAboveDividedByCommas",
    value: "Type your vocabulary in the field above, divided by commas.",
  },
  DELETE_MY_ACCOUNT: {
    key: "deleteMyAccount",
    value: "Delete my account",
  },
  DEACTIVATE_ACCOUNT: {
    key: "deactivateAccount",
    value: "Deactivate account",
  },
  ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT: {
    key: "areYouSureYouWantToDeactivateYourAccount",
    value:
      "Are you sure you want to deactivate your account? All of your data will be permanently removed from our servers forever. This action cannot be undone.",
  },
  BECOME_AN_AFFILIATE: {
    key: "becomeAnAffiliate",
    value: "Become an affiliate",
  },
  PRIVACY_POLICY: {
    key: "privacyPolicy",
    value: "Privacy policy",
  },
  TERMS_OF_SERVICE: {
    key: "termsOfService",
    value: "Terms of service",
  },
  HAVE_A_SUGGESTION: {
    key: "haveASuggestion",
    value: "Have a suggestion?",
  },
  HELP_US_ENHANCE_THE_AUDIONOTES_EXPERIENCE: {
    key: "helpUsEnhanceTheAudionotesExperience",
    value: "Help Us Enhance the AudioNotes.ai Experience!",
  },
  YOUR_NAME: {
    key: "yourName",
    value: "Your name",
  },
  YOUR_EMAIL: {
    key: "yourEmail",
    value: "Your email",
  },
  WE_VALUE_YOUR_INPUT_PLEASE_SHARE_YOUR_SUGGESTIONS_ON_HOW_WE_CAN_IMPROVE_AUDIONOTES_SERVICE:
    {
      key: "weValueYourInputPleaseShareYourSuggestionsOnHowWeCanImproveAudionotesService",
      value:
        "We value your input! Please share your suggestions on how we can improve AudioNotes.ai service.",
    },
  OPTIONAL: {
    key: "optionalKey",
    value: "Optional",
  },
  SEND: {
    key: "sendKey",
    value: "Send",
  },
  SUGGESTION: {
    key: "suggestionKey",
    value: "Suggestion",
  },
  THANK_YOU_FOR_YOUR_SUGGESTION: {
    key: "thankYouForYourSuggestion",
    value: "Thank you for your suggestion!",
  },
  GET_THE_AI_COPILOT_FOR_CLEAR_ACTIONABLE_NOTES_FROM_MEETINGS_AND_LECTURES: {
    key: "getTheAiCopilotForClearActionableNotesFromMeetingAndLectures",
    value:
      "Get the AI Copilot for Clear, Actionable Notes from Meetings and Lectures",
  },
  SAVE_TIME_EXTRACT_KEY_POINTS_AND_GAIN_DEEPER_UNDERSTANDING_WITH_AI_POWERED_ANALYSIS:
    {
      key: "saveTimeExtractKeyPointsAndGainDeeperUnderstandingWithAiPoweredAnalysis",
      value:
        "Save time, extract key points, and gain deeper understanding with AI-powered analysis.",
    },
  WEB_AND_MOBILE_RECORDING: {
    key: "webAndMobileRecording",
    value: "Web and mobile recording",
  },
  UP_TO: {
    key: "upToKey",
    value: "Up to",
  },
  MINUTER_PER_NOTE: {
    key: "minutesPerNoteKey",
    value: "minutes per note",
  },
  MINUTES_PER_MONTH: {
    key: "minutesPerMonthKey",
    value: "minutes per month",
  },
  RECORD_IN_ANY_LANGUAGE: {
    key: "recordInAnyLanguage",
    value: "Record in any language",
  },
  SAVE_UP_TO: {
    key: "saveUpToKey",
    value: "Save up to",
  },
  NOTES: {
    key: "notesKey",
    value: "notes",
  },
  MULTI_VOICE_TRANSCRIPTION: {
    key: "multiVoiceTranscription",
    value: "Multi-Voice transcription",
  },
  UNLIMITED_VOICE_NOTES: {
    key: "unlimitedVoiceNotes",
    value: "Unlimited Voice Notes",
  },
  UPLOAD_AUDIO_OR_VIDEO_FILES: {
    key: "uploadAudioOrVideoFilesKey",
    value: "Upload Audio or Video Files",
  },
  MEGABYTES: {
    key: "megabytesKey",
    value: "megabytes",
  },
  NOTES_AND_SUMMARIES_ARE_SAVED_FOREVER: {
    key: "notesAndSummariesAreSavedForever",
    value: "Notes and summaries are saved forever",
  },
  ADD_YOUR_CUSTOM_PROMPT: {
    key: "addYourCustomPrompt",
    value: "Add your custom prompt",
  },
  ANDROID: {
    key: "androidKey",
    value: "Android",
  },
  IPHONE: {
    key: "iphoneKey",
    value: "ios",
  },
  AND: {
    key: "andKey",
    value: "and",
  },
  APPS: {
    key: "appsKey",
    value: "apps",
  },
  AUTOMATIC_EXPORT_TO_NOTION: {
    key: "automaticExportToNotion",
    value: "Automatic export to Notion",
  },
  BOT: {
    key: "botKey",
    value: "bot",
  },
  WITH_ALL_AUDIONOTES_FEATURES: {
    key: "withAllAudionotesFeatures",
    value: "with All AudioNotes features",
  },
  INTEGRATION: {
    key: "integrationKey",
    value: "Integration",
  },
  MOST_POPULAR: {
    key: "mostPopular",
    value: "Most popular",
  },
  MONTH: {
    key: "monthKey",
    value: "month",
  },
  YEAR: {
    key: "yearKey",
    value: "year",
  },
  FREE: {
    key: "freeKey",
    value: "Free",
  },
  YOUR_CURRENT_PLAN: {
    key: "yourCurrentPlan",
    value: "Your current plan",
  },
  TRY_IT_FOR_FREE: {
    key: "tryItForFree",
    value: "Try it for free",
  },
  TRY_FOR_FREE_GET_STARTED_TODAY: {
    key: "tryForFreeGetStartedToday",
    value: "Try for free - get started today!",
  },
  ADVANCED: {
    key: "advancedKey",
    value: "Advanced",
  },
  PERFECT_FOR_FOCUSED_NOTES_AND_MEETINGS: {
    key: "perfectForFocusedNotesAndMeetings",
    value: "Perfect for focused notes and meetings",
  },
  PRO: {
    key: "proKey",
    value: "Pro",
  },
  UNLIMITED_NOTES_ADVANCED_FEATURES_SERIOUS_PRODUCTIVITY: {
    key: "unlimitedNotesAdvancedFeaturesSeriousProductivity",
    value: "Unlimited notes, advanced features, serious productivity",
  },
  OR_CHOOSE_A_ONE_TIME_LIFETIME_PAYMENT: {
    key: "orChooseAOneTimeLifetimePayment",
    value: "Or choose a one-time Lifetime payment",
  },
  LIFETIME_PAYMENT_FOR_ADVANCED_PLAN: {
    key: "lifetimePaymentForAdvancedPlan",
    value: "Lifetime payment for Advanced plan",
  },
  LIFETIME_PAYMENT_FOR_PRO_PLAN: {
    key: "lifetimePaymentForProPlan",
    value: "Lifetime payment for Pro plan",
  },
  WHATS_INCLUDED: {
    key: "whatsIncluded",
    value: "What's included",
  },
  PAY_ONCE_OWN_IT_FOREVER: {
    key: "payOnceOwnItForever",
    value: "Pay once, own it forever",
  },
  GET_THE_LIFETIME_DEAL: {
    key: "getTheLifetimeDeal",
    value: "Get the Lifetime deal",
  },
  MONEY_BACK_GUARANTEE_TEXT: {
    key: "moneyBackGuaranteeText",
    value:
      "60 day money-back guarantee. Try it out for 2 months to make sure it's right for you!",
  },
  SIGNED_IN_AS: {
    key: "signedInAs",
    value: "Signed in as",
  },
  MANAGE_MY_SUBSCRIPTION: {
    key: "manageMySubscription",
    value: "Manage my subscription",
  },
  ACCOUNT_SETTINGS: {
    key: "accountSettings",
    value: "Account settings",
  },
  FAVORITE_NOTES: {
    key: "favoriteNotesKey",
    value: "Favorite notes",
  },
  SIGN_OUT: {
    key: "signOut",
    value: "exit",
  },
  ACCOUNT: {
    key: "accountKey",
    value: "Account",
  },
  CONNECTING_TO_SERVER: {
    key: "connectingToServerKey",
    value: "connect to server",
  },
  INSPECTING_FILE: {
    key: "inspectingFileKey",
    value: "Inspect file",
  },
  CONVERTING_TO_WAV: {
    key: "convertingToWavFormatKey",
    value: "Convert to 'wav' format",
  },
  LANGUAGE_DETECTION: {
    key: "languageDetectionKey",
    value: "Detect language",
  },
  GET_ORIGINAL_TEXT: {
    key: "getOriginalTextKey",
    value: "Get original text",
  },
  GET_PARAGRAPH_FORM: {
    key: "getParagraphFormKey",
    value: "Get paragraph form",
  },
  GET_BULLET_POINTS: {
    key: "getBulletPointsKey",
    value: "Get bullet points",
  },
  GET_TITLE: {
    key: "getTitleKey",
    value: "Get title",
  },
  SAVE_FILE: {
    key: "saveFileKey",
    value: "Save file",
  },
  VIEW_SUMMARY: {
    key: "viewSummaryKey",
    value: "View summary",
  },
  VIEW_ORIGINAL_TRANSCRIPT: {
    key: "viewOriginalTranscriptKey",
    value: "View original transcript",
  },
  SAVED_NOTES: {
    key: "savedNotesKey",
    value: "Saved notes",
  },
  ADD_TO_FAVORITES: {
    key: "addToFavoritesKey",
    value: "Add to favorites",
  },
  REMOVE_FROM_FAVORITES: {
    key: "removeFromFavoritesKey",
    value: "Remove from favorites",
  },
  PLAY_AUDIO: {
    key: "playAudioKey",
    value: "Play audio",
  },
  PAUSE_AUDIO: {
    key: "pauseAudioKey",
    value: "Pause audio",
  },
  COPY_TEXT: {
    key: "copyTextKey",
    value: "Copy text",
  },
  ADDED_TO_FAVORITES: {
    key: "addedToFavoritesKey",
    value: "Added to favorites",
  },
  REMOVED_FROM_FAVORITES: {
    key: "removedFromFavoritesKey",
    value: "Removed from favorites",
  },
  COPIED_TO_YOUR_CLIPBOARD: {
    key: "copiedTOYourClipboardKey",
    value: "Copied to your clipboard",
  },
  DELETE_NOTE: {
    key: "deleteNoteKey",
    value: "Delete note",
  },
  NOTE_MOVED_TO_DELETED: {
    key: "noteMovedToTrashKey",
    value: "Note moved to deleted",
  },
  FAVORITE_LIST_IS_EMPTY_YET: {
    key: "favoriteListIsEmptyYetKey",
    value: "Favorite list is empty yet",
  },
  RECORD_AUDIO: {
    key: "recordAudioKey",
    value: "Record audio",
  },
  WRITE_TEXT_NOTE: {
    key: "writeTextNoteKey",
    value: "Write text note",
  },
  LOADING: {
    key: "loadingKey",
    value: "Loading",
  },
  QUICK_SEARCH: {
    key: "quickSearchKey",
    value: "Quick search",
  },
  SEARCH_RESULTS_FOR: {
    key: "searchResultsForKey",
    value: "Search results for",
  },
  DELETED_NOTES: {
    key: "deletedNotesKey",
    value: "Deleted notes",
  },
  DELETED_NOTES_EMPTY: {
    key: "deletedNotesEmptyKey",
    value: "Deleted list is empty yet",
  },
  RETURN: {
    key: "returnKey",
    value: "Return",
  },
  DELETE_FOREVER: {
    key: "deleteForeverKey",
    value: "Delete forever",
  },
  ASK_ANYTHING_ABOUT_YOUR_NOTE: {
    key: "askAnythingAboutYourNoteKey",
    value: "Ask anything about your note",
  },
  CHAT_WITH_NOTE: {
    key: "chatWithNoteKey",
    value: "Chat with note",
  },
  CHAT_WITH_YOUR_NOTES: {
    key: "chatWithYourNotesKey",
    value: "Chat with your notes!",
  },
  ASK_ANYTHING_ABOUT_YOUR_NOTES: {
    key: "askAnythingAboutYourNotesKey",
    value: "Ask anything about your notes ",
  },
  START_CONVERSATIONS_WITH_AI_USING_YOUR_NOTE_CONTENT: {
    key: "startConversationsWithAiUsingYourNoteContentKey",
    value: "Start conversations with AI using your note's content",
  },
  GENERATE_NEW_IDEAS_INSPIRED_BY_YOUR_NOTES: {
    key: "generateNewIdeasInspiredByYourNotesKey",
    value: "Generate new ideas inspired by your notes",
  },
  TEXT_NOTE_PLACEHOLDER: {
    key: "textNotePlaceholderKey",
    value:
      "Enter or paste your text note to have it automatically summarized. You’ll also have perpetual access to your original writing.",
  },
  LISTENING: {
    key: "listeningKey",
    value: "Listening",
  },
  PAUSE: {
    key: "pauseKey",
    value: "Pause",
  },
  RESUME: {
    key: "resumeKey",
    value: "Resume",
  },
  UPLOAD_FILE: {
    key: "uploadFileKey",
    value: "Upload file",
  },
  ACCOUNT_SETTINGS_SAVED: {
    key: "accountSettingsSavedKey",
    value: "Account settings saved",
  },
  DELETING_CUSTOM_PROMPT: {
    key: "deletingCustomPromptKey",
    value: "Deleting custom prompt",
  },
  UNSUPPORTED_FILE_FORMAT: {
    key: "unsupportedFileFormatKey",
    value: "Unsupported file format",
  },
  MIN_PASS_LENGTH: {
    key: "minPassLengthKey",
    value: "Minimal length 6 symbols",
  },
};
