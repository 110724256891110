import { ButtonHTMLAttributes, forwardRef, PropsWithRef } from "react";
import styled, { css, StyleSheetManager } from "styled-components";
import { UiShow, UiSpinner } from "@/shared/ui";
import isPropValid from "@emotion/is-prop-valid";

type Variant = "main" | "white" | "danger";
type Props = {
  variant: Variant;
  disabled?: boolean;
  loading?: boolean;
  children: JSX.Element;
} & PropsWithRef<ButtonHTMLAttributes<HTMLButtonElement>>;

export const UiButton = forwardRef<HTMLButtonElement, Props>(
  (
    { variant, disabled, loading, children, type = "button", ...props },
    ref,
  ) => {
    return (
      <StyleSheetManager shouldForwardProp={(prop) => isPropValid(prop)}>
        <Button
          ref={ref}
          variant={variant}
          disabled={loading || disabled}
          type={type}
          {...props}
        >
          <UiShow>
            <UiShow.When isTrue={!loading}>{children}</UiShow.When>
            <UiShow.When isTrue={!!loading}>
              <UiSpinner className="text-white" />
            </UiShow.When>
          </UiShow>
        </Button>
      </StyleSheetManager>
    );
  },
);

UiButton.displayName = "UiButton";

const Button = styled.button<Props>`
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.3s ease;
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  ${({ variant }) => variantStyles[variant]}

  .btn-loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const variantStyles: Record<Variant, ReturnType<typeof css>> = {
  main: css`
    color: #fff;
    background-color: var(--main-purple);

    &:hover {
      background-color: var(--main-purple-hover);
      text-decoration: underline;
    }
  `,
  danger: css`
    background-color: #dc2626;
    color: #fff;
    &:hover {
      background-color: #ef4444;
    }
  `,
  white: css`
    box-shadow: inset 0 0 0 1px #d1d5db;
    background-color: #fff;
    color: var(--dark);

    &:hover {
      text-decoration: underline;
    }
  `,
};
