import styled from "styled-components";
import {
  useAutoTranslation,
  useRecordProcessStore,
  useTextNoteStore,
} from "@/entities";
import { LANG_KEYS } from "@/shared/constants";
import { FileTranscribe } from "@/features";

export const FastService = () => {
  const { t } = useAutoTranslation();
  const { openTextNote } = useTextNoteStore();
  const { startFastRecord } = useRecordProcessStore();

  return (
    <Wrapper>
      <FileTranscribe btnClassname="w-full h-full" wrapper={EmptyItem}>
        <LeftItem>
          {t(LANG_KEYS.UPLOAD_FILE.key, LANG_KEYS.UPLOAD_FILE.value)}
        </LeftItem>
      </FileTranscribe>
      <Item onClick={startFastRecord}>
        {t(LANG_KEYS.RECORD_AUDIO.key, LANG_KEYS.RECORD_AUDIO.value)}
      </Item>
      <RightItem onClick={openTextNote}>
        {t(LANG_KEYS.WRITE_TEXT_NOTE.key, LANG_KEYS.WRITE_TEXT_NOTE.value)}
      </RightItem>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 40%;
  max-width: 1200px;
  margin: 0 auto;

  @media screen and (max-width: 1550px) {
    width: 70%;
  }

  @media screen and (max-width: 768px) {
    max-width: 95%;
    width: auto;
  }
`;

const Item = styled.div`
  flex: 1;
  height: 100%;
  background-color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  border-left: 1px solid rgb(229, 231, 235);
  border-right: 1px solid rgb(229, 231, 235);
  font-size: 14px;
  transition: all 0.6s ease;
  cursor: pointer;
  text-align: center;

  &:active {
    transform: scale(0.8);
  }

  @media screen and (max-width: 768px) {
    font-size: 13px;
    padding: 18px;
  }
`;

const LeftItem = styled(Item)`
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  border-color: transparent;
`;

const EmptyItem = styled.div`
  flex: 1;
`;

const RightItem = styled(Item)`
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
  border-color: transparent;
`;
