import {
  useAutoTranslation,
  useRecordProcessStore,
  useTextNoteStore,
} from "@/entities";
import { AnimatePresence, motion as m } from "framer-motion";
import { ThreeDots } from "react-loader-spinner";
import { UiSocketStep } from "@/shared/ui";
import styled from "styled-components";
import { useTranscribeProcess } from "./model";
import { useTextProcess } from "./text-model";

export const TranscribeProcess = () => {
  const { transcribing } = useRecordProcessStore();
  const { original } = useTextNoteStore();
  const { t } = useAutoTranslation();

  const { steps, currentIdx } = useTranscribeProcess();

  const { steps: textSteps, currentIdx: textCurrentIdx } = useTextProcess();

  return (
    <AnimatePresence>
      {transcribing && (
        <m.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.45, ease: "easeOut" }}
          exit={{ scale: 0 }}
        >
          <Wrapper>
            <div className="flex justify-center">
              <ThreeDots color="#fff" />
            </div>
            <StepsWrapper>
              {steps.map((item, idx) => {
                const title = t(item.data.key, item.data.value);
                return (
                  <div key={`step-socket-${idx}`}>
                    <UiSocketStep
                      text={item.text}
                      title={title}
                      done={item.done}
                      current={idx == currentIdx}
                    />
                  </div>
                );
              })}
            </StepsWrapper>
          </Wrapper>
        </m.div>
      )}
      {!!original && (
        <m.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.45, ease: "easeOut" }}
          exit={{ scale: 0 }}
        >
          <Wrapper>
            <div className="flex justify-center">
              <ThreeDots color="#fff" />
            </div>
            <StepsWrapper>
              {textSteps.map((item, idx) => {
                const title = t(item.data.key, item.data.value);
                return (
                  <div key={`step-text-socket-${idx}`}>
                    <UiSocketStep
                      text={item.text}
                      title={title}
                      done={item.done}
                      current={idx == textCurrentIdx}
                    />
                  </div>
                );
              })}
            </StepsWrapper>
          </Wrapper>
        </m.div>
      )}
    </AnimatePresence>
  );
};

const Wrapper = styled.div`
  background-color: var(--main-purple);
  padding: 20px;
  border-radius: 20px;
  width: 600px;
  margin: 0 auto;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;

const StepsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 95%;
  }
`;
