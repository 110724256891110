import { FC, ReactNode } from "react";
import styled from "styled-components";
import {
	PricingOptionsListsAdvanced,
	PricingOptionsListsFree,
	PricingOptionsListsPro,
} from "./options-lists";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

interface Props {
	title: string;
	description: string;
	price?: number;
	isCurrent?: boolean;
	isPopular?: boolean;
	isYearly?: boolean;
	optionList: OptionsList;
}

type OptionsList = "FREE" | "ADVANCED" | "PRO";

export const UiPricingPlanCard: FC<Props> = ({
	price,
	isPopular,
	description,
	title,
	isCurrent,
	isYearly,
	optionList,
}) => {
	const { t } = useAutoTranslation();
	const lists: Record<OptionsList, ReactNode> = {
		FREE: <PricingOptionsListsFree />,
		ADVANCED: <PricingOptionsListsAdvanced />,
		PRO: <PricingOptionsListsPro />,
	};

	return (
		<Wrapper $isPopular={!!isPopular}>
			<TitleWrapper>
				<Title $isPopular={!!isPopular}>{title}</Title>
				{isPopular && (
					<Popular>
						{t(
							LANG_KEYS.MOST_POPULAR.key,
							LANG_KEYS.MOST_POPULAR.value
						)}
					</Popular>
				)}
			</TitleWrapper>
			<Description>{description}</Description>
			<Price>
				{price ? (
					<>
						${price}
						<span>
							/
							{isYearly
								? t(LANG_KEYS.YEAR.key, LANG_KEYS.YEAR.value)
								: t(LANG_KEYS.MONTH.key, LANG_KEYS.MONTH.value)}
						</span>
					</>
				) : (
					<> {t(LANG_KEYS.FREE.key, LANG_KEYS.FREE.value)}</>
				)}
			</Price>

			<Button type="button" $isCurrent={!!isCurrent}>
				{isCurrent
					? t(
							LANG_KEYS.YOUR_CURRENT_PLAN.key,
							LANG_KEYS.YOUR_CURRENT_PLAN.value
					  )
					: t(
							LANG_KEYS.TRY_IT_FOR_FREE.key,
							LANG_KEYS.TRY_IT_FOR_FREE.value
					  )}
			</Button>
			<ListWrapper>{lists[optionList]}</ListWrapper>
		</Wrapper>
	);
};

const Wrapper = styled.div<{ $isPopular: boolean }>`
	width: 100%;
	height: 100%;
	padding: 40px;
	border: ${(props) =>
		!props.$isPopular ? "2px solid #e5e7eb" : "3px solid #4f46e5"};
	border-radius: 24px;
	display: flex;
	flex-direction: column;
`;

const TitleWrapper = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 24px;
`;

const Title = styled.h3<{ $isPopular: boolean }>`
	color: ${(props) => (!props.$isPopular ? "#111827" : "#4f46e5")};
	font-weight: 600;
	font-size: 18px;
`;

const Description = styled.div`
	color: #4b5563;
	font-size: 14px;
	margin-bottom: 24px;
	height: 50px;
`;

const Price = styled.h4`
	color: #111827;
	font-weight: 700;
	font-size: 36px;

	span {
		color: #4b5563;
		font-size: 14px;
		font-weight: 600;
	}
`;

const Popular = styled.div`
	background: rgba(79, 70, 229, 0.1);
	color: #4f46e5;
	padding: 4px 10px;
	font-weight: 600;
	font-size: 12px;
	border-radius: 15px;
`;

const Button = styled.button<{ $isCurrent: boolean }>`
	width: 100%;
	padding: 8px 16px;
	font-size: 14px;
	font-weight: 600;
	color: #4f46e5;
	border: 1px solid #c7d2fe;
	border-radius: 6px;
	margin-top: 24px;
	cursor: ${(props) => (props.$isCurrent ? "default" : "pointer")};

	&:hover {
		border-color: #a5b4fc;
		${(props) =>
			props.$isCurrent &&
			`
        text-decoration: underline;
      `}
	}
`;

const ListWrapper = styled.div`
	margin-top: 40px;
`;
