import styled from "styled-components";
import { UiPricingLifetimeCard } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

export const PricingLifetimeCards = () => {
  const { t } = useAutoTranslation();
  return (
    <Wrapper>
      <UiPricingLifetimeCard
        title={t(
          LANG_KEYS.LIFETIME_PAYMENT_FOR_ADVANCED_PLAN.key,
          LANG_KEYS.LIFETIME_PAYMENT_FOR_ADVANCED_PLAN.value,
        )}
        price={129}
        optionList="ADVANCED"
      />
      <UiPricingLifetimeCard
        title={t(
          LANG_KEYS.LIFETIME_PAYMENT_FOR_PRO_PLAN.key,
          LANG_KEYS.LIFETIME_PAYMENT_FOR_PRO_PLAN.value,
        )}
        price={289}
        optionList="PRO"
      />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-top: 80px;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;
