import styled from "styled-components";
import { UiHeading } from "@/shared/ui";
import { LANG_KEYS, ROUTER_PATHS } from "@/shared/constants";
import { NotesDeleted } from "@/widgets";
import { useAutoTranslation, useUserStore } from "@/entities";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

export const TrashNotesPage = () => {
  const { t } = useAutoTranslation();
  const { email } = useUserStore();
  const navigate = useNavigate();

  useEffect(() => {
    if (!email) {
      navigate(ROUTER_PATHS.HOME, { replace: true });
    }
  }, [email]);

  return (
    <Section>
      <Wrapper>
        <UiHeading>
          {t(LANG_KEYS.DELETED_NOTES.key, LANG_KEYS.DELETED_NOTES.value)}
        </UiHeading>
        <NotesDeleted />
      </Wrapper>
    </Section>
  );
};

const Section = styled.section``;
const Wrapper = styled.div`
  width: 90%;
  margin: 0 auto;
`;
