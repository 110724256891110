import { ReactNode, useEffect, useState } from "react";
import { UiLoading, UiShow } from "@/shared/ui";
import { useTranslation } from "react-i18next";
import { AnimatePresence, motion as m } from "framer-motion";
import { useLangStore, useRecordOptionsStore, useUserStore } from "@/entities";
import { translationControllerGetLangsList } from "@/shared/api/generated";
import { getDictionary } from "@/shared/api/dictionary.ts";

export const PreloadProvider = ({ children }: { children: ReactNode }) => {
  const [isDone, setDone] = useState(false);
  const { i18n } = useTranslation();
  const { setData, lang } = useLangStore();
  const { client_access_token, access_token, generateClientAuth, login } =
    useUserStore();

  const { invalidateOptions } = useRecordOptionsStore();

  const handlePreload = async () => {
    const res = await translationControllerGetLangsList();
    setData(res);
    const currentDictionary = await getDictionary(lang);
    i18n.addResources(lang, "translation", currentDictionary);
    await i18n.changeLanguage(lang);

    let token = "";
    if (!client_access_token) {
      token = await generateClientAuth();
    } else {
      token = access_token;
    }
    login(token);

    await invalidateOptions();

    setDone(true);
  };

  useEffect(() => {
    handlePreload();
  }, []);

  return (
    <>
      <UiShow>
        <UiShow.When isTrue={!isDone}>
          <AnimatePresence mode="wait">
            <m.div
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              <UiLoading fullScreen />
            </m.div>
          </AnimatePresence>
        </UiShow.When>
        <UiShow.Else>
          <AnimatePresence mode="wait">
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.75, ease: "easeOut" }}
              exit={{ opacity: 0 }}
            >
              {children}
            </m.div>
          </AnimatePresence>
        </UiShow.Else>
      </UiShow>
    </>
  );
};
