import { FastService, NotesList, OptionsSettings } from "@/widgets";
import styled from "styled-components";
import { RecordTranscribe } from "@/features";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";
import { TranscribeProcess } from "@/features/transcribe";

export const HomePage = () => {
  // useEffect(() => {
  //   translateAllKeys();
  // }, []);

  const { t } = useAutoTranslation();
  return (
    <HomeSection>
      <HomeWrapper>
        <Headings>
          <Subtitle>
            {t(
              LANG_KEYS.SUPERCHARGE_YOUR_PRODUCTIVITY.key,
              LANG_KEYS.SUPERCHARGE_YOUR_PRODUCTIVITY.value,
            )}
          </Subtitle>
          <Title>
            {t(
              LANG_KEYS.TRANSFORM_YOUR_THOUGHTS_INTO_CLEAR_TEXT_NOTES.key,
              LANG_KEYS.TRANSFORM_YOUR_THOUGHTS_INTO_CLEAR_TEXT_NOTES.value,
            )}
          </Title>
        </Headings>
        <RecordingWrapper>
          <TranscribeProcess />
          <RecordTranscribe />
          <div className="my-8 flex justify-center">
            <OptionsSettings />
          </div>
          <div className="my-6">
            <FastService />
          </div>
        </RecordingWrapper>
      </HomeWrapper>
      <NotesList />
    </HomeSection>
  );
};

const Subtitle = styled.h2`
  color: var(--main-purple);
  font-weight: 600;
  font-size: 1rem;
  text-align: center;
`;

const Title = styled.h1`
  font-size: 4rem;
  font-weight: 700;
  color: #111827;
  text-align: center;
  max-width: 700px;
  line-height: 75px;
  white-space: pre-wrap;
  word-break: break-word;

  @media screen and (max-width: 768px) {
    font-size: 3.2rem;
    line-height: 60px;
  }
`;

const HomeSection = styled.section`
  padding: 35px 15px;
`;

const HomeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Headings = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const RecordingWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;
  width: 100%;
`;
