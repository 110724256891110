import { useForm } from "react-hook-form";
import {
  authControllerSignUp as mutationFn,
  AuthDtoReq,
} from "@/shared/api/generated.ts";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  useAutoTranslation,
  useRecordOptionsStore,
  useUserStore,
} from "@/entities";
import { LANG_KEYS, QUERY_KEYS } from "@/shared/constants";

type FormData = AuthDtoReq & { confirmPassword: string };

export function useSignUpForm() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
    reset,
  } = useForm<FormData>();

  const { login } = useUserStore();
  const { t } = useAutoTranslation();
  const queryClient = useQueryClient();
  const { invalidateOptions } = useRecordOptionsStore();

  const formMutation = useMutation({
    mutationFn,
    async onSuccess({ access_token }) {
      login(access_token);
      reset();
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.NOTES });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.FAVORITE });
      await queryClient.invalidateQueries({ queryKey: QUERY_KEYS.DELETED });
      await invalidateOptions();
    },
  });

  const NOT_MATCH_PASSWORDS_ERROR = {
    type: "custom",
    message: t(
      LANG_KEYS.PASSWORDS_DONT_MATCH.key,
      LANG_KEYS.PASSWORDS_DONT_MATCH.value,
    ),
  };

  return {
    register,
    handleSubmit: handleSubmit((values) => {
      if (values.password !== values.confirmPassword) {
        setError("password", NOT_MATCH_PASSWORDS_ERROR);
        setError("confirmPassword", NOT_MATCH_PASSWORDS_ERROR);
        return;
      }
      formMutation.mutate({
        email: values.email,
        password: values.password,
      });
    }),
    isLoading: formMutation.isPending,
    errors,
  };
}
