import styled from "styled-components";
import { FC, ReactNode } from "react";
import {
  PricingLifetimeAdvancedLists,
  PricingLifetimeProLists,
} from "./options-lists";
import { UiButton } from "@/shared/ui";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

type OptionsList = "ADVANCED" | "PRO";
interface Props {
  title: string;
  price: number;
  optionList: OptionsList;
}

export const UiPricingLifetimeCard: FC<Props> = ({
  title,
  price,
  optionList,
}) => {
  const { t } = useAutoTranslation();
  const lists: Record<OptionsList, ReactNode> = {
    ADVANCED: <PricingLifetimeAdvancedLists />,
    PRO: <PricingLifetimeProLists />,
  };

  return (
    <Wrapper>
      <Left>
        <Title>{title}</Title>
        <Subtitle>
          {t(LANG_KEYS.WHATS_INCLUDED.key, LANG_KEYS.WHATS_INCLUDED.value)}
        </Subtitle>
        <ListWrapper>{lists[optionList]}</ListWrapper>
      </Left>
      <Right>
        <RightInner>
          <PriceTitle>
            {t(
              LANG_KEYS.PAY_ONCE_OWN_IT_FOREVER.key,
              LANG_KEYS.PAY_ONCE_OWN_IT_FOREVER.value,
            )}
          </PriceTitle>
          <PriceContent>
            <p>${price}</p>
            <span>USD</span>
          </PriceContent>
          <UiButton className="w-full" variant="main" type="button">
            <div>
              {t(
                LANG_KEYS.GET_THE_LIFETIME_DEAL.key,
                LANG_KEYS.GET_THE_LIFETIME_DEAL.value,
              )}
            </div>
          </UiButton>
          <PriceDescription>
            {t(
              LANG_KEYS.MONEY_BACK_GUARANTEE_TEXT.key,
              LANG_KEYS.MONEY_BACK_GUARANTEE_TEXT.value,
            )}
          </PriceDescription>
        </RightInner>
      </Right>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  border: 2px solid #e5e7eb;
  border-radius: 24px;
  display: flex;
  justify-content: space-between;

  @media screen and (max-width: 1050px) {
    flex-direction: column;
  }
`;

const Left = styled.div`
  padding: 40px;
`;
const Right = styled.div`
  padding: 8px;
`;

const RightInner = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  padding: 64px 88px;
  @media screen and (max-width: 1050px) {
    padding: 64px 30px;
    width: 100%;
  }
`;

const Title = styled.h4`
  color: #0f172a;
  font-weight: 700;
  font-size: 24px;
  margin-bottom: 40px;
`;

const Subtitle = styled.h5`
  color: #4f46e5;
  font-weight: 600;
  font-size: 14px;
`;

const ListWrapper = styled.div`
  margin-top: 32px;
`;

const PriceTitle = styled.h5`
  color: #4b5563;
  font-weight: 600;
  font-size: 16px;
  text-align: center;
  margin-bottom: 24px;
`;

const PriceContent = styled.div`
  color: #111827;
  font-weight: 700;
  font-size: 48px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
  margin-bottom: 40px;

  p {
    line-height: 42px;
  }

  span {
    color: #4b5563;
    font-size: 14px;
    font-weight: 600;
  }
`;

const PriceDescription = styled.p`
  color: #4b5563;
  font-size: 12px;
  text-align: center;
  max-width: 250px;
  word-break: break-word;
  margin-top: 24px;
`;
