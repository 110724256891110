import styled from "styled-components";
import { LANG_KEYS } from "@/shared/constants";
import { useAutoTranslation } from "@/entities";

export const SocialAuth = () => {
	const { t } = useAutoTranslation();
	return (
		<Wrapper>
			<Top>
				<Line className="flex-1" />
				<Text>
					{t(
						LANG_KEYS.OR_CONTINUE_WITH.key,
						LANG_KEYS.OR_CONTINUE_WITH.value
					)}
				</Text>
				<Line className="flex-1" />
			</Top>

			{/*TODO AUTH WITH GOOGLE AND APPLE FIREBASE*/}
			<Content>
				<GoogleBtn type="button">
					<img src="/logo-google.svg" alt="#" />
					<span>Google</span>
				</GoogleBtn>
				<AppleBtn type="button">
					<img src="/logo-apple.svg" alt="#" />
					<span>Sign in with Apple</span>
				</AppleBtn>
			</Content>
		</Wrapper>
	);
};

const Wrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const Line = styled.div`
	width: 100%;
	height: 1px;
	background-color: #e5e7eb;
`;

const Top = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 24px;
`;

const Text = styled.div`
	color: #111827;
	font-weight: 500;
	font-size: 14px;
`;

const Content = styled.div`
	margin-top: 24px;
	display: flex;
	flex-direction: column;
	gap: 24px;
`;

const MainBtn = styled.button`
	display: flex;
	justify-content: center;
	gap: 12px;
	width: 100%;
	padding: 8px 12px;
	border-radius: 6px;
	border: 1px solid #000;

	img {
		width: 20px;
		height: 20px;
		object-fit: contain;
	}

	span {
		font-weight: 600;
		font-size: 14px;
	}
`;

const GoogleBtn = styled(MainBtn)`
	background-color: #f9f9f9;
`;

const AppleBtn = styled(MainBtn)`
	background-color: #000;
	color: #fff;
`;
